<!--
--------------------------------------------------------------------------------
<copyright file="ManufacturingPOFinishedDueDate.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.manufacturing_po_due_date_time_intervals') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.time_scale_h') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">{{ $t('widget_info_text.manufacturing_po_due_date_bars') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.parameters') }}</summary>
      <img src="images/info/threeDotsMenu.jpg" class="list-item" />
      <p class="text">
        {{ $t('widget_info_text.manufacturing_due_date_filter') }}
      </p>
    </details>
  </section>
</template>

<script>
export default {};
</script>

<style></style>
